import HSPWStrength from "../admin/pwstrength-bootstrap.min";
import HSTogglePassword from "../admin/hs-toggle-password.min";
import HSUnfold from "../admin/hs-unfold";
import HSDatatables from "../admin/jquery.dataTables.min";
import HSFancyBox from "../admin/jquery.fancybox.js";
$( document ).ajaxComplete(function() {
    // Required for Bootstrap tooltips in DataTables
    $('[data-toggle="tooltip"]').tooltip({
        "html": true,
        "delay": {"show": 1000, "hide": 0},
    });
});
document.addEventListener("turbolinks:load", function () {

    $('.stripe-button-el').addClass('d-none');

    $('.js-validate').each(function() {
        $.HSCore.components.HSValidation.init($(this));
    });
    $('.js-pwstrength').each(function () {
        var pwstrength = $.HSCore.components.HSPWStrength.init($(this));
    });
    var sidebar = $('.js-navbar-vertical-aside').hsSideNav();
    $('.js-circle').each(function () {
        var circle = $.HSCore.components.HSCircles.init($(this));
    });
    $('.js-fancybox').each(function() {
        var fancybox = $.HSCore.components.HSFancyBox.init($(this));
    })
    $('.js-hs-unfold-invoker').each(function () {
        var unfold = new HSUnfold($(this)).init();
    });
    if ($("#datatable").length > 0) {
        var datatable = $.HSCore.components.HSDatatables.init($('#datatable'), {
            dom: 'Bfrtip',
        }).on('draw.dt', function () {
            $('.datatable-custom .js-hs-unfold-invoker').each(function () {
                new HSUnfold($(this)).init();
            });
            $('[data-toggle="tooltip"]').tooltip();
        });
    }
    $('input[type=radio][name="user[roles]"]').on('change', function () {
        $('input[type=radio][name="user[roles]"]').next().addClass("avatar-soft-primary").removeClass("avatar-primary")
        $(this).next().addClass("avatar-primary").removeClass("avatar-soft-primary")
        if($(this).val() == "Broker") {
            $("#user_phone_no, #user-timing, #user-notify").closest(".user-phone").addClass("d-none")
            $(".user-timing, .user-notify").addClass("d-none")
            $("#user_company_name").closest(".user-comp").removeClass("d-none")
        }
        else{
            $("#user_phone_no").closest(".user-phone").removeClass("d-none")
            $(".user-timing, .user-notify").removeClass("d-none")
            $("#user_company_name").closest(".user-comp").addClass("d-none")
        }
    });
    $('#edit_contract').validate({
        ignore: ":hidden",
        'contract[buyer_email]': {required: true}
    });
    $('.select-user-text').on("change", function(e) {
        $.ajax({
            type: 'get',
            url: "/brokers/contracts/find_user",
            data: {email: $(this).val()},
            success: function (response, status, xhr) {
                if(response.user != ''){
                    $("#contract_buyer_name").val(response.user.name).attr('disabled', 'disabled')
                    $("#contract_buyer_phone").val(response.user.phone_no).attr('disabled', 'disabled')
                }
                else{
                    $("#contract_buyer_name").val('').removeAttr('disabled')
                    $("#contract_buyer_phone").val('').removeAttr('disabled')
                }
            },
        });
    });
    $('.select-user').on("change", function(e) {
        $.ajax({
            type: 'get',
            url: "/brokers/contracts/find_user",
            data: {email: $(this).val()},
            success: function (response, status, xhr) {
                if(response.user != ''){
                    $("#contract_buyer_name").val(response.user.name).attr('disabled', 'disabled')
                    $("#contract_buyer_phone").val(response.user.phone_no).attr('disabled', 'disabled')
                }
                else{
                    $("#contract_buyer_name").val('').removeAttr('disabled')
                    $("#contract_buyer_phone").val('').removeAttr('disabled')
                }
            },
        });
    });

    $('.custom-select2').select2();
    $('.tool_tip').tooltip();
    $('#submit-contract').click(function () {
        if($('.edit_contract').valid()) {
            $.ajax({
                type: 'PUT',
                url: $(".edit_contract").attr("action"),
                data: $("#edit_contract").serialize(),
                success: function (response, status, xhr) {
                },
            });
        }
    });
    $('#UploadModal, #templateModal').on('show.bs.modal', function (e) {
        $('.c-name').val($('#contract_name').val());
        $('.c-discription').val($('#contract_legal_discription').val());
        $('.c-mls-listing').val($('#contract_mls_listing').val());
        $('.c-address').val($('#contract_address').val());
    });
    $('.contract-uplod-type').on('change', function (e) {
        if($(this).val() == "template"){
            $(".upload-contract-pdf-btn").addClass('d-none')
            $(".use-temp-btn").removeClass('d-none')
        }
        else{
            $(".upload-contract-pdf-btn").removeClass('d-none')
            $(".use-temp-btn").addClass('d-none')
        }
    });
    $('.multi_step_form ul#progressbar li').css('width', "calc(100%/"+$('.multi_step_form ul#progressbar').children().length+")")

    $(".search-name").on("keyup", function () {
        $.ajax({
            type: "POST",
            dataType: "script",
            url: '/brokers/contracts/search',
            data: {query: $(this).val()},
            success: function (data) {
            }
        });
    });

    $(".user-search-contract").on("keyup", function () {
        $.ajax({
            type: "POST",
            dataType: "script",
            url: $("#user-search-contract").attr('action'),
            data: {query: $(this).val()},
            success: function (data) {
            }
        });
    });

    $(".filepond").each(function() {
        if ($(this).data("name")){
            createPoster(this, $(this).data("name"), $(this).data("size"), $(this).data("type"), $(this).data("url"))
        } else {
            FilePond.create(this, {
                name: 'filepond',
                credits: false,
            });
        }
    });

    $("#changeEmailLink").click(function (){
        $("#changeEmail").removeClass('d-none')
    });

    $(".toggle-password").click(function() {
        $(this).toggleClass("fa-eye fa-eye-slash");
        var input = $($(this).attr("toggle"));
        if (input.attr("type") == "password") {
            input.attr("type", "text");
        } else {
            input.attr("type", "password");
        }
    });

    // $('.card-deck').slick({
    //     slidesToShow: 2,
    //     slidesToScroll: 1,
    //     touchMove: true,
    //     speed: 500,
    //     autoplaySpeed: 3000,
    //     arrows: true,
    //     infinite: true,
    //     dots: false
    // });
    $('#start_date').flatpickr({
        dateFormat: 'm/d/Y'
    })
    $('.event-dtaepiker').flatpickr({
        dateFormat: 'm/d/Y'
    })

    $(".event-dtaepiker").on('input', function(e) {
        var month = this._flatpickr.latestSelectedDateObj.getMonth() + 1
        var year = this._flatpickr.latestSelectedDateObj.getFullYear()
        var date = this._flatpickr.latestSelectedDateObj.getDate()
        $.ajax({
            type: 'put',
            dataType: 'script',
            url: '/brokers/events/'+$(this).attr('data-event-id'),
            data: {event: {deadline_date: month+"/"+date+"/"+year}},
            success: function(data){
            }
        })
    });
});
$(document).on('change','.contract-template-select', function (e) {
    if($(this).attr("data-pre_populate_date") == 'true'){
        $('#contract-start-date').removeClass('d-none')
        $('#contract-start-date').find('#start_date').attr('required','required')
        $('#start_date').flatpickr({
            dateFormat: 'm/d/Y'
        })
    }
    else{
        $('#contract-start-date').addClass('d-none')
        $('#contract-start-date').find('#start_date').removeAttr('required')
    }
});
$(document).on('click', 'table tr.eventRow', function () {
    $(this).removeClass('eventRow');
    $.get($(this).attr("data-url"), function (data) {
    }, "Script")
});
// $(document).on('click', 'table tr.doc-row', function () {
//     $(this).removeClass('doc-row');
//     $.get("/brokers/documentations/" + $(this).attr("data-id") + "/edit?contract_id=" + ($("#contract_id").val()), function (data) {
//     }, "Script")
// });
// $(document).on('click', 'table tr.user-doc-row', function () {
//     $(this).removeClass('user-doc-row');
//     $.get("/users/documentations/" + $(this).attr("data-id") + "/edit?contract_id=" + ($("#contract_id").val()), function (data) {
//     }, "Script")
// });
jQuery.fn.keyupQueue = function(callback, delay) {
    return this.each(function(){
        var timer = null;
        $(this).keyup(function() {
            if (timer)
                window.clearTimeout(timer);
            timer = window.setTimeout( function() {
                timer = null;
                callback();
            }, delay || 200);
        });
    });
};
$(document).ajaxSend(function(e) {
    if(e.currentTarget.activeElement.id != 'datatableSearch'){
        $("#page-overlay").fadeIn(300);
    }
    else{
        $('table').addClass('loading');
    }
});
$(document).on('click','.contract-delete, .doc-delete', function() {
    $("#page-overlay").fadeIn(300);
});
$(document).ajaxComplete(function(e) {
    console.log(e)
    if(e.currentTarget.activeElement.id != 'datatableSearch'){
        setTimeout(function(){
            $("#page-overlay").fadeOut(300);
        },500);
    }
    else{
        setTimeout(function(){
            $('table').removeClass('loading');
        },500);
    }
});
$(document).on('change', '.change_enevet_status', function () {
    $.ajax({
        type: 'put',
        dataType: 'script',
        url: '/brokers/events/'+$(this).attr('data-id'),
        data: {event: {status: $(this).val()}},
        success: function(data){
        }
    })
});
$(document).on('click', '#contract-file-submit', function () {
    $(this).text("Uploading").attr('disabled', true);
    var methodType = $('#file_upload_contract_id').val() == "" ? "post" : "put"
    $.ajax({
        type: methodType,
        url: $("#file-upload-form").attr("action"),
        data: $('#file-upload-form').serialize(),
        dataType: "json",
        success: function (response, status, xhr) {
            var setinterval = window.setInterval(function(){
                $.get("/brokers/contracts/"+ response.id +"/get_events", {}, function (res){
                    if(res.status == "complete"){
                        clearInterval(setinterval)
                        $("#file-progressbar").css({"width": "100%", "transition": "none"}).addClass('d-none');
                        $("#contract-file-submit").removeAttr('disabled').addClass('d-none');
                        $("#countinue-contract-btn").removeClass('d-none').attr('href', "/brokers/contracts/" + response.id + "/edit")
                        $(".progress").addClass('d-none');
                    }else if(res.status == "failed"){
                        clearInterval(setinterval)
                        $(".custom-file").prepend("<div class='text-danger'>Failed to extract PDF</div>")
                        $("#file-progressbar").css({"width": "10%", "transition": "none"})
                        $('#contract-file-submit').text("submit").attr('disabled', false);
                    }
                });
            }, 10000);
        },
        beforeSend: function (e) {
            $("#file-progressbar").css({"width": "90%", "transition": "50s"});

        }
    });
});
export function createPoster(input, name, size, type, url) {
    FilePond.create(input, {
        name: 'filepond',
        credits: false,
        files: [
            {
                options: {
                    file: {
                        name: name,
                        size: size,
                        type: type
                    },
                    metadata: {
                        poster: url
                    }
                }
            }
        ],
    });
}
export function print_doc(file_path)
{
    var w = window.open(file_path);
    w.onafterprint = function(e){
        w.close();
    };
    w.print();
}
