// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require("jquery");
require("bootstrap");
require("popper.js");
require("filepond")
require("flatpickr")
require("../admin/jquery-migrate.min");
require("../admin/bootstrap.bundle");

require("../admin/hs-navbar-vertical-aside.min");
require("../admin/appear.min");
import Circles from "../admin/circles.min";
window.Circles = Circles
require("../admin/Chart.min")
require("../admin/theme.min")
// require("../theme/main");
require("../custom/custom");
require("../custom/jquery.validate");
require("../custom/slick.min");
require("../custom/auto_scroll_pagination");

require("trix")
require("@rails/actiontext")
import $ from "jquery";
global.$ = jQuery;
global.toastr = require("toastr")
import 'flatpickr';
import * as FilePond from 'filepond';
window.FilePond = FilePond;
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
window.FilePondPluginFileEncode = FilePondPluginFileEncode;
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
window.FilePondPluginFileValidateType = FilePondPluginFileValidateType;

FilePond.registerPlugin(
    FilePondPluginFileEncode,
    FilePondPluginFileValidateType
);
import  "../stylesheets/application.scss";
import 'select2'

import {createPoster, print_doc} from '../custom/custom'
window.createPoster = createPoster
window.print_doc = print_doc
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
