document.addEventListener("turbolinks:load", function () {
    $('.tableFixHead').on('scroll', function() {
        if ($('#next_link').data("loading")) {
            return
        }
        if($(".tableFixHead").scrollTop() + $(".tableFixHead").innerHeight() >= $(".tableFixHead")[0].scrollHeight) {
            $('#next_link')[0].click();
            $('#next_link').data("loading", true);
        }

    })
});
